import React from 'react';

export default class Login extends React.Component {
	render() {
		return (
			<div>
				<a className="link" href="/auth/github">Login with GitHub</a>
			</div>
		);
	}
}